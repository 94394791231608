import styled from "styled-components";

export const OuterModal = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #333;
  z-index: 2;
  opacity: 0.8;
`;
